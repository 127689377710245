import imgPregradoPresencial from "../../images/imgPregradoPresencial.png";
import imgPregradoVirtual from "../../images/imgPregradoVirtual.png";
import imgPregradoDistancia from "../../images/imgPregradoDistancia.png";
import imgPosgradoPresencial from "../../images/imgPosgradoPresencial.png";
import imgPosgradoVirtual from "../../images/imgPosgradoVirtual.png";

// SECCION DE PREGRADO PRESENCIAL
export const apiPregradosPresencial = [
  {
    id: 1,
    img: imgPregradoPresencial,
    titleCard: "Pregrados Presenciales",
    description:
      "El pregrado es tu primer paso en la vida universitaria, por eso la Americana te brinda una variedad de programas de alta calidad y de manera presencial, para ampliar tus horizontes y convertirte en un profesional íntegro.",
    children: [
      {
        id: 1,
        titleArea: "Psicología",
        titleCourse: "Psicología.",
        percent: "50%",
        valueSemester: "$ 4.320.470",
        valueDiscount: "$ 2.160.235",
        valueBeca: "$ 2.365.784",
      },
      {
        id: 2,
        titleArea: "Ingeniería Industrial",
        titleCourse: "Ingeniería Industrial.",
        percent: "50%",
        valueSemester: "$ 5.058.043",
        valueDiscount: "$ 2.529.021",
        valueBeca: "$ 2.529.021",
      },
      {
        id: 3,
        titleArea: "Derecho",
        titleCourse: "Derecho.",
        percent: "50%",
        valueSemester: "$ 4.108.038",
        valueDiscount: "$ 2.054.019",
        valueBeca: "$ 2.054.019",
      },
      {
        id: 4,
        titleArea: "Ingeniería de Sistemas",
        titleCourse: "Técnico Profesional en Programación de Computadores.",
        percent: "50%",
        valueSemester: "$ 3.379.692",
        valueDiscount: "$ 1.689.846",
        valueBeca: "$ 1.689.846",
      },
      {
        id: 5,
        titleArea: "Ingeniería de Sistemas",
        titleCourse: "Tecnología en Desarrollo de Software.",
        percent: "50%",
        valueSemester: "$ 3.862.505",
        valueDiscount: "$ 1.931.252",
        valueBeca: "$ 1.931.252",
      },
      {
        id: 6,
        titleArea: "Ingeniería de Sistemas",
        titleCourse: "Profesional en Ingeniería de Sistemas.",
        percent: "50%",
        valueSemester: "$ 4.828.132",
        valueDiscount: "$ 2.414.066",
        valueBeca: "$ 2.414.066",
      },
      {
        id: 7,
        titleArea: "Administración de Empresas",
        titleCourse: "Técnico Profesional en Procesos Empresariales.",
        percent: "50%",
        valueSemester: "$ 3.379.692",
        valueDiscount: "$ 1.689.846",
        valueBeca: "$ 1.689.846",
      },
      {
        id: 8,
        titleArea: "Administración de Empresas",
        titleCourse: "Tecnología en Gestión Empresarial.",
        percent: "50%",
        valueSemester: "$ 3.862.505",
        valueDiscount: "$ 1.931.252",
        valueBeca: "$ 1.931.252",
      },
      {
        id: 9,
        titleArea: "Administración de Empresas",
        titleCourse: "Profesional en Administración de Empresas.",
        percent: "50%",
        valueSemester: "$ 4.828.132",
        valueDiscount: "$ 2.414.066",
        valueBeca: "$ 2.414.066",
      },
      {
        id: 10,
        titleArea: "Contaduría Pública",
        titleCourse: "Técnico Profesional en Procesos Contables.",
        percent: "50%",
        valueSemester: "$ 3.138.286",
        valueDiscount: "$ 1.569.143",
        valueBeca: "$ 1.569.143",
      },
      {
        id: 11,
        titleArea: "Contaduría Pública",
        titleCourse: "Tecnología en Gestión Contable.",
        percent: "50%",
        valueSemester: "$ 3.621.098",
        valueDiscount: "$ 1.810.549",
        valueBeca: "$ 1.810.549",
      },
      {
        id: 12,
        titleArea: "Contaduría Pública",
        titleCourse: "Profesional en Contaduría Pública.",
        percent: "50%",
        valueSemester: "$ 4.020.007",
        valueDiscount: "$ 2.010.003",
        valueBeca: "$ 2.010.003",
      },
      {
        id: 13,
        titleArea: "Negocios Internacionales",
        titleCourse:
          "Técnico Profesional en Procesos Logísticos y de Comercio Exterior.",

        percent: "50%",
        valueSemester: "$ 3.379.692",
        valueDiscount: "$ 1.689.846",
        valueBeca: "$ 1.689.846",
      },
      {
        id: 14,
        titleArea: "Negocios Internacionales",
        titleCourse:
          "Tecnología en Gestión Logística y de Comercio Internacional.",
        percent: "50%",
        valueSemester: "$ 3.862.505",
        valueDiscount: "$ 1.931.252",
        valueBeca: "$ 1.931.252",
      },
      {
        id: 15,
        titleArea: "Negocios Internacionales",
        titleCourse: "Profesional en Negocios Internacionales.",
        percent: "50%",
        valueSemester: "$ 4.828.132",
        valueDiscount: "$ 2.414.066",
        valueBeca: "$ 2.414.066",
      },
      {
        id: 16,
        titleArea: "Licenciatura en Educación Bilingüe",
        titleCourse: "Licenciatura en Educación Bilingüe.",
        percent: "50%",
        valueSemester: "$ 4.212.850",
        valueDiscount: "$ 2.106.425",
        valueBeca: "$ 2.106.425",
      },
      {
        id: 17,
        titleArea: "Licenciatura en Educación Infantil",
        titleCourse: "Licenciatura en Educación Infantil.",
        percent: "50%",
        valueSemester: "$ 4.045.674",
        valueDiscount: "$ 2.022.837",
        valueBeca: "$ 2.022.837",
      },
      {
        id: 18,
        titleArea: "Comunicación Social",
        titleCourse: "Comunicación Social.",
        percent: "50%",
        valueSemester: "$ 4.509.121",
        valueDiscount: "$ 2.254.560",
        valueBeca: "$ 2.254.560",
      },
      {
        id: 19,
        titleArea: "Administración Turistica y Hotelera",
        titleCourse: "Técnico Profesional en Procesos Turísticos y Hoteleros.",
        percent: "50%",
        valueSemester: "$ 2.839.166",
        valueDiscount: "$ 1.419.583",
        valueBeca: "$ 1.419.583",
      },
      {
        id: 20,
        titleArea: "Administración Turistica y Hotelera",
        titleCourse: "Tecnología en Gestión de Turismo y Hoteles.",
        percent: "50%",
        valueSemester: "$ 3.548.958",
        valueDiscount: "$ 1.774.479",
        valueBeca: "$ 1.774.479",
      },
      {
        id: 21,
        titleArea: "Administración Turistica y Hotelera",
        titleCourse: "Administración Turística y Hotelera.",
        percent: "50%",
        valueSemester: "$ 4.471.686",
        valueDiscount: "$ 2.235.843",
        valueBeca: "$ 2.235.843",
      },
      {
        id: 22,
        titleArea: "Tecnología en Higiene y Seguridad en el Trabajo",
        titleCourse: "Tecnología en Higiene y Seguridad en el Trabajo",
        percent: "50%",
        valueSemester: "$ 4.054.868",
        valueDiscount: "$ 2.027.434",
        valueBeca: "$ 2.027.434",
      },
    ],
  },
];
// SECCION DE PREGRADO VIRTUAL
export const apiPregradosVirtual = [
  {
    id: 1,
    img: imgPregradoVirtual,
    titleCard: "Pregrados Virtuales",
    description:
      "En la Americana no existen límites de tiempo ni espacio, es por eso que nuestra oferta de pregrados virtuales te brindan la oportunidad de formarte desde cualquier lugar. ¡Que nada te detenga y sé imparable!",
    children: [
      {
        id: 1,
        titleArea: "Administración de Empresas",
        titleCourse: "Técnico Profesional en Procesos Empresariales.",
        percent: "60%",
        valueSemester: "$ 3.033.812",
        valueDiscount: "$ 1.820.287",
        valueBeca: "$ 1.213.524",
      },
      {
        id: 2,
        titleArea: "Administración de Empresas",
        titleCourse: "Tecnología en Gestión Empresarial.",
        percent: "60%",
        valueSemester: "$ 3.467.214",
        valueDiscount: "$ 2.080.328",
        valueBeca: "$ 1.386.885",
      },
      {
        id: 3,
        titleArea: "Administración de Empresas",
        titleCourse: "Profesional en Administración de Empresas.",
        percent: "60%",
        valueSemester: "$ 4.334.017",
        valueDiscount: "$ 2.600.410",
        valueBeca: "$ 1.733.606",
      },
      {
        id: 4,
        titleArea: "Contaduría Pública",
        titleCourse: "Técnico Profesional en Procesos Contables.",
        percent: "60%",
        valueSemester: "$ 2.817.111",
        valueDiscount: "$ 1.690.266",
        valueBeca: "$ 1.126.844",
      },
      {
        id: 5,
        titleArea: "Contaduría Pública",
        titleCourse: "Tecnología en Gestión Contable.",
        percent: "60%",
        valueSemester: "$ 3.250.513",
        valueDiscount: "$ 1.950.307",
        valueBeca: "$ 1.300.205",
      },
      {
        id: 6,
        titleArea: "Contaduría Pública",
        titleCourse: "Profesional en Contaduría Pública.",
        percent: "60%",
        valueSemester: "$ 3.608.596",
        valueDiscount: "$ 2.165.157",
        valueBeca: "$ 1.443.438",
      },
      {
        id: 7,
        titleArea: "Negocios Internacionales",
        titleCourse:
          "Técnico Profesional en Procesos Logísticos y de Comercio Exterior.",
        percent: "60%",
        valueSemester: "$ 3.033.812",
        valueDiscount: "$ 1.820.287",
        valueBeca: "$ 1.213.524",
      },
      {
        id: 8,
        titleArea: "Negocios Internacionales",
        titleCourse:
          "Tecnología en Gestión Logística y de Comercio Internacional.",
        percent: "60%",
        valueSemester: "$ 3.467.214",
        valueDiscount: "$ 2.080.328",
        valueBeca: "$ 1.386.885",
      },
      {
        id: 9,
        titleArea: "Negocios Internacionales",
        titleCourse: "Profesional en Negocios Internacionales.",
        percent: "60%",
        valueSemester: "$ 4.334.017",
        valueDiscount: "$ 2.600.410",
        valueBeca: "$ 1.733.606",
      },
      {
        id: 10,
        titleArea: "Ingeniería Industrial",
        titleCourse: "Ingeniería Industrial.",
        percent: "60%",
        valueSemester: "$ 4.540.399",
        valueDiscount: "$ 2.724.239",
        valueBeca: "$ 1.816.159",
      },
    ],
  },
];

// SECCION DE PREGRADO DISTANCIA
export const apiPregradosDistancia = [
  {
    id: 1,
    img: imgPregradoDistancia,
    titleCard: "Pregrados a Distancia",
    description:
      "¡A un click de distancia! Conviértete en un profesional de manera innovadora y con flexibilidad horaria en la Americana y sé invencible.",
    children: [
      {
        id: 1,
        titleArea: "Administración Pública",
        titleCourse: "Administración Pública.",
        percent: "50%",
        valueSemester: "$ 4.207.766",
        valueDiscount: "$ 2.524.660",
        valueBeca: "$ 1.683.106",
      },
      {
        id: 2,
        titleArea: "Ingeniería en Seguridad y Salud en el Trabajo",
        titleCourse: "Ingeniería en Seguridad y Salud en el Trabajo.",
        percent: "50%",
        valueSemester: "$ 4.207.766",
        valueDiscount: "$ 2.524.660",
        valueBeca: "$ 1.683.106",
      },
      {
        id: 3,
        titleArea: "Licenciatura en Educación Infantil",
        titleCourse: "Licenciatura en Educación Infantil.",
        percent: "50%",
        valueSemester: "$ 2.858.019",
        valueDiscount: "$ 1.714.811",
        valueBeca: "$ 1.143.208",
      },
      {
        id: 4,
        titleArea: "Manejo Agroindustrial",
        titleCourse: "Técnica en Manejo Agroindustrial.",
        percent: "50%",
        valueSemester: "$ 4.189.500",
        valueDiscount: "$ 2.513.700",
        valueBeca: "$ 1.675.800",
      },
      {
        id: 5,
        titleArea: "Tecnología en Producción Agroindustrial",
        titleCourse: "Tecnología en Producción Agroindustrial.",
        percent: "50%",
        valueSemester: "$ 4.608.450",
        valueDiscount: "$ 2.765.070",
        valueBeca: "$ 1.843.380",
      },
      {
        id: 6,
        titleArea: "Procesos Financieros",
        titleCourse: "Técnica Profesional en Procesos Financieros.",
        percent: "50%",
        valueSemester: "$ 2.182.950",
        valueDiscount: "$ 1.309.770",
        valueBeca: "$ 873.180",
      },
      {
        id: 7,
        titleArea: "Gestión Financiera",
        titleCourse: "Tecnólogo en Gestión Financiera.",
        percent: "50%",
        valueSemester: "$ 2.403.450",
        valueDiscount: "$ 1.442.070",
        valueBeca: "$ 961.380",
      },
    ],
  },
];

// SECCION DE POSGRADO PRESENCIAL
export const apiPosgradosPresencial = [
  {
    id: 1,
    img: imgPosgradoPresencial,
    titleCard: "Posgrados Presenciales",
    description:
      "La Escuela de Posgrados, busca seguir construyendo el futuro de los profesionales que están en la constante necesidad de expandir  sus competencias y conocimientos.",
    children: [
      {
        id: 1,
        titleArea: "Especialización",
        titleCourse: "Especialización en Derecho Constitucional.",
        percent: "50%",
        valueSemester: "$ 6.977.919",
        valueDiscount: "$ 3.488.960",
        valueBeca: "$ 3.488.960",
      },
      {
        id: 2,
        titleArea: "Especialización",
        titleCourse: "Especialización en Derecho Administrativo.",
        percent: "50%",
        valueSemester: "$ 6.977.919",
        valueDiscount: "$ 3.488.960",
        valueBeca: "$ 3.488.960",
      },
      {
        id: 3,
        titleArea: "Especialización",
        titleCourse: "Especialización en Derecho Laboral.",
        percent: "50%",
        valueSemester: "$ 6.672.326",
        valueDiscount: "$ 3.336.163",
        valueBeca: "$ 3.336.163",
      },
      {
        id: 4,
        titleArea: "Especialización",
        titleCourse: "Especialización en Derecho Penal.",
        percent: "50%",
        valueSemester: "$ 6.218.071",
        valueDiscount: "$ 3.109.036",
        valueBeca: "$ 3.109.036",
      },
      {
        id: 5,
        titleArea: "Especialización",
        titleCourse: "Especialización Gerencia de Mercadeo Estrategico.",
        percent: "50%",
        valueSemester: "$ 6.923.841",
        valueDiscount: "$ 3.461.921",
        valueBeca: "$ 3.461.921",
      },
      {
        id: 6,
        titleArea: "Especialización",
        titleCourse: "Especialización Gerencia Empresarial y Competitividad.",
        percent: "50%",
        valueSemester: "$ 6.923.841",
        valueDiscount: "$ 3.461.921",
        valueBeca: "$ 3.461.921",
      },
      {
        id: 7,
        titleArea: "Especialización",
        titleCourse: "Especialización Contabilidad Internacional.",
        percent: "50%",
        valueSemester: "$ 5.945.755",
        valueDiscount: "$ 2.972.878",
        valueBeca: "$ 2.972.878",
      },
      {
        id: 8,
        titleArea: "Especialización",
        titleCourse: "Especialización en Gerencia Tributaria.",
        percent: "50%",
        valueSemester: "$ 6.836.951",
        valueDiscount: "$ 3.418.476",
        valueBeca: "$ 3.418.476",
      },
      {
        id: 9,
        titleArea: "Especialización",
        titleCourse: "Especialización en Gerencia de Proyectos.",
        percent: "50%",
        valueSemester: "$ 7.282.667",
        valueDiscount: "$ 3.641.334",
        valueBeca: "$ 3.641.334",
      },
      {
        id: 10,
        titleArea: "Especialización",
        titleCourse:
          "Especialización en Gerencia Logística en Comercio Internacional.",
        percent: "50%",
        valueSemester: "$ 6.968.862",
        valueDiscount: "$ 3.484.431",
        valueBeca: "$ 3.484.431",
      },
      {
        id: 11,
        titleArea: "Especialización",
        titleCourse: "Especialización en Innovación Educativa y TIC.",
        percent: "50%",
        valueSemester: "$ 5.118.358",
        valueDiscount: "$ 2.559.179",
        valueBeca: "$ 2.559.179",
      },
      {
        id: 12,
        titleArea: "Especialización",
        titleCourse: "Especialización en Seguridad Informática.",
        percent: "50%",
        valueSemester: "$ 6.646.886",
        valueDiscount: "$ 3.323.443",
        valueBeca: "$ 3.323.443",
      },
      {
        id: 13,
        titleArea: "Especialización",
        titleCourse: "Especialización en Finanzas.",
        percent: "50%",
        valueSemester: "$ 6.646.886",
        valueDiscount: "$ 3.323.443",
        valueBeca: "$ 3.323.443",
      },
    ],
  },
];

// SECCION DE POSGRADO VIRTUAL
export const apiPosgradosVirtual = [
  {
    id: 1,
    img: imgPosgradoVirtual,
    titleCard: "Posgrados Virtuales",
    description:
      "Asume un nuevo reto profesional, estudia desde cualquier lugar con nuestra oferta de Posgrados Virtuales para fortalecer tus competencias y aumentar tus oportunidades laborales.",
    children: [
      {
        id: 1,
        titleArea: "Especialización",
        titleCourse: "Gerencia de Proyectos.",
        percent: "50%",
        valueSemester: "$ 7.282.667",
        valueDiscount: "$ 3.641.334",
        valueBeca: "$ 3.641.334",
      },
      {
        id: 2,
        titleArea: "Especialización",
        titleCourse: "Derecho Laboral.",
        percent: "50%",
        valueSemester: "$ 6.672.326",
        valueDiscount: "$ 3.336.163",
        valueBeca: "$ 3.336.163",
      },
      {
        id: 3,
        titleArea: "Especialización",
        titleCourse: "Innovación Educativa.",
        percent: "50%",
        valueSemester: "$ 6.480.779",
        valueDiscount: "$ 3.240.390",
        valueBeca: "$ 3.240.390",
      },
      {
        id: 4,
        titleArea: "Especialización",
        titleCourse: "Contabilidad Internacional.",
        percent: "50%",
        valueSemester: "$ 5.945.755",
        valueDiscount: "$ 2.972.878",
        valueBeca: "$ 2.972.878",
      },
    ],
  },
];
