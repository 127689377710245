import { useState } from "react";
import { Box, Button, Collapse, Grid, Typography } from "@mui/material";

// IMPORTADOS
import "./Home.scss";
import { Colors } from "../const";

const Cards = ({ data = {}, colorBorde = Colors.accentColor }) => {
  const [open, setOpen] = useState(false);
  const openCourses = () => {
    setOpen(!open);
  };
  return (
    <>
      <Box className="containerCard">
        <Box className="contentCard">
          {/****************/}
          {/* IMAGEN CARTA */}
          {/****************/}
          <img src={data.img} alt="" />

          {/***************/}
          {/* IMPORMACION */}
          {/***************/}
          <Box className="contentInfo">
            {/***************/}
            {/* TITULO INFO */}
            {/***************/}
            <Typography component={"h1"}>{data.titleCard}</Typography>

            {/***************/}
            {/* DESCRIPCION */}
            {/***************/}
            <Typography>{data.description}</Typography>

            {/*******************/}
            {/* BOTON PROGRAMAS */}
            {/*******************/}
            <Button onClick={openCourses} disableTouchRipple>
              {open ? <>Ver Menos</> : <>Ver Programas</>}
            </Button>
          </Box>
        </Box>
      </Box>

      {/********************/}
      {/* CONTENIDO OCULTO */}
      {/********************/}
      <Collapse in={open}>
        <Grid
          className="cardInfo"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {data.children.map((child, key) => (
            // CONTENEDOR SUB CARD
            <Grid item sm={12} md={6} lg={4} className="grid" key={key}>
              {/************/}
              {/* SUB CARD */}
              {/************/}
              <Box
                className="subCard"
                sx={{
                  borderLeft: `20px solid ${colorBorde}`,
                }}
              >
                <Box>
                  <Typography className="titleArea">
                    {child.titleArea}
                  </Typography>

                  <Typography component="h1">{child.titleCourse}</Typography>

                  <Typography>Valor Semestre Pleno</Typography>
                  <span>{child.valueSemester}</span>

                  <Typography>Valor beca {child.percent} descuento.</Typography>
                  <span>{child.valueDiscount}</span>

                  <Typography>Valor a pagar del becado.</Typography>
                  <span>{child.valueBeca}</span>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </>
  );
};

export default Cards;
