import { Box, Typography } from "@mui/material";

// IMPORTADOS
import "./BackgroundImage.scss";

const BackgroundImage = () => {
  return (
    // CONTENIDO DE IMAGEN
    <Box className="containerImage">
      {/********************/}
      {/* CONTENIDO TITULO */}
      {/********************/}
      <Box className="contentTitle">
        <Box>
          <Typography>
            Beca de <br /> Responsabilidad Social.
          </Typography>

          <Typography component={"span"}>
            Promoviendo la inclusión y el acceso a la Educación Superior de la
            juventud Colombiana; la Institución Universitaria Americana, hace un
            aporte de carácter social para la construcción de una sociedad mas
            justa, equitativa e incluyente.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BackgroundImage;
