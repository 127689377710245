// CONSTANTES DE COLOR
export const Colors = {
  // AMARILLO
  primaryColor: "#fecc58",

  // FUCSIA
  secondaryColor: "#E32C6D",

  // AZUL
  accentColor: "#1300FD",
};

// CONSTANTE FORMULARIO
export const GoogleFormUrl =
  "https://docs.google.com/forms/d/e/1FAIpQLScYi-vRaqrGj5GpzgxQaO90kWNl2D4HLKkLnWtCp8ztICF8iQ/viewform";
