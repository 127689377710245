import { Box } from '@mui/material';

// IMPORTADOS
// import logo from "./logo.svg";
// import "./App.css";
import Header from './components/layout/header/Header';
import Home from './pages/Home';
import Footer from './components/layout/footer/Footer';
import BackgroundImage from './pages/BackgroundImage';

function App() {
  return (
    <Box>
      {/**********/}
      {/* HEADER */}
      {/**********/}
      <Header />

      {/********************/}
      {/* BACKGROUND IMAGE */}
      {/********************/}
      <BackgroundImage />

      {/********/}
      {/* HOME */}
      {/********/}
      <Home />

      {/**********/}
      {/* FOOTER */}
      {/**********/}
      <Footer />
    </Box>
  );
}

export default App;
