// import React from "react";
import { Box, Typography } from "@mui/material";

// IMPORTADOS
import "./Home.scss";
import Cards from "./Cards";
import {
  apiPregradosPresencial,
  apiPregradosVirtual,
  apiPregradosDistancia,
  apiPosgradosPresencial,
  apiPosgradosVirtual,
} from "../components/data/dataApi";

import { Colors, GoogleFormUrl } from "../const";

const Home = () => {
  return (
    <Box className="containerHome">
      {/******************************/}
      {/* CARD PREGRADOS Y POSGRADOS */}
      {/******************************/}
      <Box className="contentHome">
        {/***********************/}
        {/* PREGRADO PRESENCIAL */}
        {/***********************/}
        {apiPregradosPresencial.map((data, key) => (
          <Cards key={key} data={data} colorBorde={Colors.primaryColor} />
        ))}

        {/********************/}
        {/* PREGRADO VIRTUAL */}
        {/********************/}
        {apiPregradosVirtual.map((data, key) => (
          <Cards key={key} data={data} colorBorde={Colors.secondaryColor} />
        ))}

        {/**********************/}
        {/* PREGRADO DISTANCIA */}
        {/**********************/}
        {apiPregradosDistancia.map((data, key) => (
          <Cards key={key} data={data} colorBorde={Colors.accentColor} />
        ))}

        {/***********************/}
        {/* POSGRADO PRESENCIAL */}
        {/***********************/}
        {apiPosgradosPresencial.map((data, key) => (
          <Cards key={key} data={data} colorBorde={Colors.secondaryColor} />
        ))}

        {/********************/}
        {/* POSGRADO VIRTUAL */}
        {/********************/}
        {apiPosgradosVirtual.map((data, key) => (
          <Cards key={key} data={data} colorBorde={Colors.primaryColor} />
        ))}
      </Box>

      {/***************/}
      {/* INFORMACION */}
      {/***************/}
      <Box className="contentBtn">
        <Typography
          component="a"
          href={GoogleFormUrl}
          target="_blank"
          className="btnFormulario"
        >
          Diligenciar Formulario
        </Typography>

        <Typography>Para mayor información comunicarse a</Typography>

        <Typography component="span">301 286 0939</Typography>
      </Box>
    </Box>
  );
};

export default Home;
