import React from "react";
import { Box } from "@mui/material";

// IMPORTADOS
import "./Footer.scss";
import IconLogoAmericana from "../../icons/IconLogoAmericana";

const Footer = () => {
  return (
    <Box className="containerFooter">
      <Box>
        <IconLogoAmericana />
      </Box>
    </Box>
  );
};

export default Footer;
