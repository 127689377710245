import React from "react";
import { Box } from "@mui/material";

// IMPORTADOS
import "./Header.scss";
import IconLogoAmericana from "../../icons/IconLogoAmericana";

const Header = () => {
  return (
    <Box className="containerHeader">
      <Box>
        <IconLogoAmericana color1="white" color2="#B3B9C2" />
      </Box>
    </Box>
  );
};

export default Header;
